@import '../colours';
@import '../general';

@font-face {
    font-family: DIYCenturyGothic;
    src: url('../../resources/static/fonts/gothic.woff');
}

@font-face {
    font-family: DIYLogo;
    src: url('../../resources/static/fonts/logo.woff');
}

.diy-nav-link {
    text-decoration: none;
    color: $dark-blue;

    &:hover {
        color: $dark-blue2;
    }
}

.app {
    margin: auto;
    color: $dark-blue;
    font: 400 1rem DIYCenturyGothic, Century Gothic, CenturyGothic, AppleGothic, sans-serif;

    // Background Colours
    .diy-dark-blue-background-colour {
        background-color: $dark-blue;
    }
    .diy-light-orange-background-colour {
        background-color: $light-orange;
    }
    .diy-dark-blue2-background-colour {
        background-color: $dark-blue2;
    }
    .diy-light-grey-background-colour {
        background-color: $light-grey;
    }
    .diy-white-background-colour {
        background-color: $white;
    }
    .diy-dark-cornflower-blue-background-colour {
        background-color: $dark-cornflower-blue;
    }

    .diy-page-content {
        @extend %page-content;
    }
}
