%diy-icon-start {
    background-image: url('../resources/static/images/start.svg');
}

%diy-icon-blue-pin {
    color: #003f7f;
}

%diy-icon-red-pin {
    color: #c92a2a;
}

%diy-icon-green-pin {
    color: #007f00;
}

%diy-icon-grey-pin {
    color: gray;
}
