@import '../general';

.diy-trip-detail {
    margin: auto;
}

.diy-trip-designer {
    height: 45rem;

    textarea {
        display: block;
        min-width: $component-min-width;
        height: 10rem;
        width: 100%;
        margin-bottom: 6px;
    }

    .diy-trip-designer-input-select {
        .MuiGrid-item {
            font-size: smaller;
            font-weight: bold;
        }
    }

    .diy-trip-designer-gid-input {
        margin-bottom: 12px;

        .MuiTextField-root {
            width: 80%;
        }
    }

    .diy-poi-name {
        span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 80%;

            &:hover {
                max-width: none;
                z-index: 3;
            }
        }
    }

    .marker {
        font-family: DIYLogo, sans-serif;
        font-size: 1.5em;
    }
}
