@import '../colours';
@import '../general';

.diy-team-member {
    min-width: $component-min-width;
    text-align: center;

    .diy-image {
        margin-right: 1rem;
        margin-left: 3rem;
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
    }

    .diy-team-member-detail {
        margin-top: 24px;
        color: $dark-blue;

        .diy-team-member-name {
            color: $light-orange;
            font-weight: bold;
            font-size: 2rem;

            @media screen and (max-width: $screen-max-width) {
                & {
                    font-size: 1rem;
                }
            }
        }

        .diy-team-member-purpose {
            height: 3rem;

            @media screen and (max-width: $screen-max-width) {
                & {
                    height: auto;
                }
            }
        }

        .diy-team-member-social {
            margin-top: 12px;
            margin-bottom: 12px;

            .diy-social-icon {
                font-size: 3rem;

                &.diy-linkedin-icon {
                    color: #2867b2;
                }

                &.diy-twitter-icon {
                    color: #ffffff;
                    background-color: #1da1f2;
                    border-radius: 5px;
                    font-size: 2.35rem;
                }

                &.diy-facebook-icon {
                    color: #3b5998;
                }
            }
        }
    }
}
