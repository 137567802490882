@import '../../colours';
@import '../../general';

.diy-about-screen {
    @media screen and (min-width: $screen-min-width) {
        & {
            font-size: 1.4rem;
        }
    }

    @media screen and (max-width: $screen-max-width) {
        & {
            font-size: 0.8rem;
        }
    }

    h3 {
        font-weight: normal;
        text-align: center;
    }

    h2 {
        text-align: center;
        color: $header-colour;
        padding: 10px;
        font-weight: normal;
        font-size: 2.5rem;
    }

    .diy-about-us {
        position: relative;
        flex-grow: 1;

        h2 {
            background-color: $light-orange;
        }

        .diy-about-us-media {
            position: relative;
            min-width: $component-min-width;
        }

        .diy-about-us-detail {
            position: relative;
            min-width: $component-min-width;
            margin: auto;
            vertical-align: middle;

            p {
                margin-top: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .diy-about-how-it-works {
        h2 {
            background-color: $dark-blue;
        }

        .diy-image {
            img {
                width: 5vw;

                @media screen and (max-width: $screen-max-width) {
                    & {
                        width: 15vw;
                    }
                }
            }
        }

        .diy-about-hiw-detail {
            margin-bottom: 2rem;

            .div-about-how-it-works-detail {
                vertical-align: middle;
                margin-top: 16px;

                @media screen and (max-width: $screen-max-width) {
                    & {
                        margin-top: 0;
                    }
                }

                .div-about-how-it-works-title {
                    font-size: 2rem;
                    vertical-align: bottom;

                    @media screen and (max-width: $screen-max-width) {
                        & {
                            font-size: 1rem;
                        }
                    }
                }

                .div-about-how-it-works-subtitle {
                    font-size: 1.5rem;
                    color: $light-orange;

                    @media screen and (max-width: $screen-max-width) {
                        & {
                            font-size: 0.9rem;
                        }
                    }
                }
            }
        }
    }

    .diy-about-team {
        h2 {
            background-color: $light-orange;
        }
    }
}

iframe {
    width: 100%;
}
