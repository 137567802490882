@import '../../colours';
@import '../../general';

.diy-other-regions {
    text-align: center;
    padding-bottom: 2rem;

    @media screen and (min-width: $screen-min-width) {
        & {
            font-size: 0.9rem;
        }
    }

    @media screen and (max-width: $screen-max-width) {
        & {
            font-size: 0.7rem;
        }
    }

    a {
        display: inline-grid;
        vertical-align: middle;

        span {
            padding: 2px 6px;
            text-align: center;
            white-space: normal;
        }

        &.diy-region-title-other {
            font-weight: bold;
            text-decoration: underline;
        }
    }
}

.diy-region-home {
    .diy-trips-row {
        .diy-trip-item {
            @extend %component-detail;

            .diy-trip-media {
                width: 100%;

                .diy-trip-title {
                    text-align: center;
                    padding: 0.5rem;
                }
            }
        }
    }
}
