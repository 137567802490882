@import '../../colours';
@import '../../general';

.diy-home-screen-grid {
    position: relative;
    padding-top: 8px;

    .diy-home-screen-column {
        @extend %component-detail;

        .diy-home-screen-comp {
            text-align: center;
            color: $header-colour;
            position: relative;

            img {
                width: 100%;
            }

            a {
                span {
                    color: white;
                    background-color: rgba(0, 0, 0, 0.1);
                    display: block;
                    width: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    margin: auto;

                    @media screen and (min-width: $screen-min-width) {
                        & {
                            font-size: 2.5rem;
                        }
                    }

                    @media screen and (max-width: $screen-max-width) {
                        & {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
    }
}
