@import './icons';

.marker {
    &.diy-marker-navigation-point {
        display: none;
    }

    &.diy-marker-poi {
        @extend %diy-icon-red-pin;
    }

    &.diy-marker-drive-point {
        @extend %diy-icon-blue-pin;
    }

    &.diy-map-pin-unselected {
        @extend %diy-icon-grey-pin;
    }
}
