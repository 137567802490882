// Screen widths for desktop vs Mobile layout
$screen-min-width: 801px;
$screen-max-width: 800px;

$container-max-width: 1180px;
$component-min-width: 350px;

%page-content {
    max-width: $container-max-width;
    margin: auto;
}

%component-detail {
    min-width: $component-min-width;
    margin: auto;
    position: relative;
}
